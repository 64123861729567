import * as React from 'react';
import { graphql, PageRendererProps } from 'gatsby';
import { Redirect } from '@reach/router';
import BaseLayout from '../components/layouts/BaseLayout';
import { ModuleRenderer } from '../modules/ModuleRenderer';
import { getPageAttributes } from '../modules/ModuleRenderer/utils';

interface IPersonDetailTemplate {
  data: Queries.PersonDetailTemplateQuery;
}

const PersonDetailTemplate: React.FC<
  IPersonDetailTemplate & PageRendererProps
> = ({ data: { page }, ...props }) => {
  if (!page) {
    console.warn('Unable to get data from wordpress pages query');
    return <Redirect to="/404" />;
  }

  const pageAttributes = getPageAttributes(page);

  return (
    <BaseLayout
      seo={{
        title: `${page.title}`,
        location: props.location.href,
        ...pageAttributes,
      }}
    >
      <ModuleRenderer
        blocks={page.blocks}
        pageAttributes={{ title: `${page.title}`, ...pageAttributes }}
      />
    </BaseLayout>
  );
};

export default PersonDetailTemplate;

export const query = graphql`
  query PersonDetailTemplate($id: String!) {
    page: wpPeople(id: { eq: $id }) {
      ...PersonDetailPage
    }
  }
`;
